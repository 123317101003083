import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { RiArrowDownSLine } from 'react-icons/ri';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

import exclamation from '../../../assets/media/icons/Group4790.svg';
import CancelImage from '../../../assets/media/icons/failed.svg';
import Info from "../../../assets/media/icons/info.png"

import { getFixerConvertedAmount } from '../../cards/api';
import { getOrderLimitAPI } from '../api';
import { Modals } from '../../../shared';
import { ROUTES } from '../../../routes';
import { useRewardPoints } from '../../rewardPoints/hooks/useRewardPoints';

const CartWidget = props => {
  const { t } = useTranslation();
  const { state, authState, rewardState, history, handleChangeCurrency, createCheckout, setUseGiftiGlobalPoints, useGiftiGlobalPoints } = props;
  const { total_credits } = useRewardPoints();

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement:"right"
  });

  const [showOrderLimitModal, setShowOrderLimitModal] = useState(false);
  const [isRedeemable, setIsRedeemable] = useState(true)

  useEffect(()=>{
    let redeemable = false;
    state?.lineItems?.forEach(val=>{
      if(val?.is_redeemable_for_gg_points === true){
        redeemable = true;
      }
    })
    if(redeemable) return setIsRedeemable(false)
    setIsRedeemable(true)
    
  },[state])

  // eslint-disable-next-line react/prop-types
  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <Button
      variant="link"
      className="p-0 align-top shadow-none"
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <RiArrowDownSLine />
    </Button>
  ));
  CustomToggle.displayName = 'CustomToggle';

  const getMarginAmount = amount => {
    const additionalCharge = (parseFloat(amount) * 5) / 100;
    return parseFloat(additionalCharge + parseFloat(amount)).toFixed(2);
  };
  const getTruncatedAmount = amount => {
    return parseFloat(amount).toFixed(2);
  };

  const getExtraAmountForStripe = () => {
    return getTruncatedAmount(state.conversion?.minimum_payable_amount);
  };

  const lineValue =
    state && state.lineItems && state.lineItems.length
      ? state.lineItems.reduce((sum, i) => sum + i.selectedDenomination * i.quantity)
      : 0;

  const getCardValueForAuthenticatedUser = item => {
    // Same currency
    if (item.currency === state.selectedCartCurrency?.unit_name_short) {
      // With Markup Percentage
      if (item.markup_percentage) {
        return item.giftcard_value_with_markup;
      } else {
        // Without Markup
        return item.giftcard_value;
      }
    } else {
      // Different Currency
      // If used gifti points we use conversion to selectedCardCurrency
      // if (useGiftiGlobalPoints) {
      //   return item.card_value_aed_with_markup * state.conversion?.currency_exchange_rate;
      // }
      if(item.card_value_aed_with_markup === 0){
        return item.card_value_aed
      }
      return item.card_value_aed_with_markup;
    }
  };

  const getCardValueForAnonymousUser = item => {
    // Same currency
    if (item.currency === state.selectedCartCurrency?.unit_name_short) {
      // With Markup Percentage
      if (item.markup_percentage) {
        // AED card value
        if (state.selectedCartCurrency?.unit_name_short === 'AED') {
          return _addPersent(+item.card_value_aed, item.markup_percentage);
        }
        // Markup percentage with other currencies
        return _addPersent(+item.giftcard_value, item.markup_percentage);
      } else {
        // Without Markup
        // AED card value
        // if (item.currency === 'AED') {
        //   return item.card_value_aed;
        // }
        // Not AED card values
        return item.giftcard_value;
      }
    } else {
      // Different Currency
      // With Markup Percentage
      if (item.markup_percentage) {
        // AED card value
        if (state.selectedCartCurrency?.unit_name_short === 'AED') {
          return _addPersent(+item.card_value_aed, item.markup_percentage);
        }
        // Markup percentage with other currencies
        return _addPersent(+item.card_value_aed, item.markup_percentage);
      } else {
        // Without Markup
        // Always returning AED card value for different currencies without Markup
        return +item.card_value_aed;
      }
    }

    function _addPersent(value, persent) {
      return value * (1 + persent / 100);
    }
  };

  const getCardValue = item => {
    return authState.isAuthenticated ? getCardValueForAuthenticatedUser(item) : getCardValueForAnonymousUser(item);
  };

  const getConvertedAmountWithMargin = () => {
    let total = 0;
    let final_amount = 0;
    if (state.lineItems.length) {
      for (let i = 0; i < state.lineItems.length; i++) {
        if (state.lineItems[i].currency !== state.selectedCartCurrency?.unit_name_short) {
          final_amount = parseFloat(
            getMarginAmount(
              getCardValue(state.lineItems[i]) *
              parseInt(state.lineItems[i].quantity) *
              state.conversion?.currency_exchange_rate
            )
          );
        } else {
          final_amount = getCardValue(state.lineItems[i]) * parseInt(state.lineItems[i].quantity);
        }
        total += final_amount;
      }
    }
    total = getTruncatedAmount(total);

    if (isNaN(total)) return '----';

    return total < state.conversion?.minimum_payable_amount && total > 0
      ? getExtraAmountForStripe()
      : getTruncatedAmount(total);
  };

  const getConvertedAmountWithoutMargin = () => {
    let total = 0;
    let final_amount = 0;
    if (state.lineItems.length) {
      for (let i = 0; i < state.lineItems.length; i++) {
        if (state.lineItems[i].currency !== state.selectedCartCurrency?.unit_name_short) {
          final_amount = parseFloat(getCardValue(state.lineItems[i]) * parseInt(state.lineItems[i].quantity) * state.conversion?.currency_exchange_rate);
        } else {
          final_amount = getCardValue(state.lineItems[i]) * parseInt(state.lineItems[i].quantity);
        }
        total += final_amount;
      }
    }
    return getTruncatedAmount(total)
  };

  // use when we click on the giftiglobal points to display subtotal that will be sent on the order_total_aed in params
  const finalSubTotal = () => {
    return getTruncatedAmount(parseFloat(getTotalConvertedAmount(true)) + parseFloat(convertedGiftiPoints()));
  };

  // # This is the actual payable amount used only when we click on use points
  const getTotalConvertedAmount = usePoints => {
    if (usePoints) {
      let total1 = 0;
      let final_amount = 0;
      let amount_for_margin = 0;
      const currencyExchangeRate = state.conversion?.currency_exchange_rate;
      if (state.lineItems.length) {
        for (let i = 0; i < state.lineItems.length; i++) {
          if (state.lineItems[i].currency !== state.selectedCartCurrency?.unit_name_short) {
            final_amount = parseFloat(getCardValue(state.lineItems[i]) * state.lineItems[i].quantity * currencyExchangeRate) ;
            amount_for_margin += final_amount;
          } else {
            final_amount = getCardValue(state.lineItems[i]) * parseInt(state.lineItems[i].quantity);
          }
          total1 += final_amount;
        }
      }
      let totalRewardPoints = rewardState?.total_credits || 0;
      totalRewardPoints = currencyExchangeRate
        ? parseFloat(totalRewardPoints * currencyExchangeRate)
        : parseFloat(totalRewardPoints);
      if (totalRewardPoints > total1) {
        return 0;
      } else {
        let payable_amount = parseFloat(total1 - totalRewardPoints);
        let margin_percentage_for_payable_amount = (amount_for_margin / total1) * 100;
        let amount_for_margin_new = getTruncatedAmount((payable_amount * margin_percentage_for_payable_amount) / 100);
        let temp_payable_amount = getTruncatedAmount(amount_for_margin_new * 0.05);
        payable_amount = getTruncatedAmount(payable_amount + parseFloat(temp_payable_amount));
        return payable_amount < state.conversion?.minimum_payable_amount && payable_amount > 0
          ? getExtraAmountForStripe()
          : getTruncatedAmount(payable_amount);
      }
    }
  };

  // Used Reward points displayed on the cart page with payment currency
  const convertedGiftiPoints = () => {
    const totalAmount = getConvertedAmountWithoutMargin();
    const giftiGlobalPoints = rewardState?.total_credits || 0;
    const selectedCurrency = state.selectedCartCurrency;
    const conversionRate = state.conversion?.currency_exchange_rate;
    if (selectedCurrency && selectedCurrency.id && conversionRate) {
      let pointsToCurrency = giftiGlobalPoints * conversionRate;
      if (totalAmount > pointsToCurrency) {
        return getTruncatedAmount(pointsToCurrency);
      }
      return totalAmount;
    } else {
      if (totalAmount > parseFloat(giftiGlobalPoints)) {
        return getTruncatedAmount(giftiGlobalPoints);
      }
      return getTruncatedAmount(totalAmount);
    }
  };

  // Remaining user points after click on the use credits
  const getUpdatedRewardPoints = () => {
    let totalAmount = 0;
    if (!useGiftiGlobalPoints) {
      totalAmount = parseFloat(getConvertedAmountWithMargin());
    } else {
      totalAmount = parseFloat(getConvertedAmountWithoutMargin());
    }
    const currencyExchangeRate = state.conversion?.currency_exchange_rate;
    let totalRewardPoints = (rewardState?.total_credits || 0) * currencyExchangeRate;
    if (totalRewardPoints > totalAmount) {
      totalRewardPoints = (totalRewardPoints - totalAmount) / currencyExchangeRate;
    } else {
      totalRewardPoints = 0;
    }
    return parseFloat(totalRewardPoints).toFixed(2);
  };

  const getTotalOrderCount = (limit = 0) => {
    if (!state || !state.lineItems || !state.lineItems.length) {
      return 0;
    }
    return limit + state.lineItems.reduce((a, b) => a + b);
  };

  // Calculating use_credits in AED to send in the params
  const calculateUsedCredits = (lineItems, selectedCurrency) => {
    if (!lineItems || !lineItems?.length) return 0;
    let total_amount_without_margin = getConvertedAmountWithoutMargin();
    let use_credits_in_payment_currency = convertedGiftiPoints();
    let payment_diff = total_amount_without_margin - use_credits_in_payment_currency
    if (payment_diff > 0)
    {
      return getTruncatedAmount(rewardState?.total_credits);
    }
    else
    {
      return getTruncatedAmount(use_credits_in_payment_currency /  state.conversion?.currency_exchange_rate);
    }
  };

  const checkout = async () => {
    if(useGiftiGlobalPoints && !isRedeemable) return
    if (authState.isAuthenticated) {
      const orderLimitResponse = await getOrderLimitAPI();
      if (orderLimitResponse && orderLimitResponse.code === 200) {
        const limit = orderLimitResponse?.data?.order_limit || 0;
        if (limit > 4 || getTotalOrderCount(limit) > 4) {
          setShowOrderLimitModal(true);
          return;
        }
      }
    }
    let totalAmount = getConvertedAmountWithMargin();
    let convertedAmountAED = totalAmount;
    if (state.selectedCartCurrency?.unit_name_short !== 'AED') {
      const response = await getFixerConvertedAmount(
        convertedAmountAED,
        state.selectedCartCurrency?.unit_name_short,
        'AED'
      );
      convertedAmountAED = response.data.converted_amount;
    }
    const checkoutObject = {
      total_amount: totalAmount,
      amount_to_pay: totalAmount,
      total_amount_aed: parseFloat(convertedAmountAED).toFixed(2),
      currency: state.selectedCartCurrency?.unit_name_short,
      currency_id: state.selectedCartCurrency?.id,
    };
    if (authState.isAuthenticated) {
      checkoutObject.are_reward_points_used = useGiftiGlobalPoints;
      checkoutObject.reward_points = getUpdatedRewardPoints();
      if (useGiftiGlobalPoints) {
        const diffAmount =
            parseFloat(totalAmount) - parseFloat(convertedGiftiPoints());
        const isDiffAmountToBePaid = diffAmount > 0;
        const usedCreditPoints = calculateUsedCredits(state.lineItems, state.selectedCartCurrency?.unit_name_short);
        checkoutObject.total_amount = finalSubTotal();
        checkoutObject.amount_to_pay = getTotalConvertedAmount(true);
        checkoutObject.isDiffAmountToBePaid = Number(state?.pricebreakdown?.total) > 0;
        checkoutObject.total_amount_aed = usedCreditPoints
        checkoutObject.used_credits = usedCreditPoints
      }
    }
    createCheckout(checkoutObject);
  };

  return (
    <div className="cart-first-column p-3">
      <div className="d-flex align-items-end">
        <h6 className="flex-grow-1">{t('cart.widget.title')}</h6>
        <div className="flex-shrink-1 cart-currency p-1">
          <small>{t('cart.widget.selectPaymentCurrency')}</small>
          <span className="mx-2">|</span>
          <Dropdown className="d-inline" onSelect={e => handleChangeCurrency(e)}>
            <DropdownToggle as={CustomToggle} id="dropdown-custom-components" />
            <DropdownMenu align="right" style={{ overflow: 'auto' }}>
              {state.paymentCurrency.map((currency, key) => (
                <DropdownItem
                  key={key}
                  eventKey={JSON.stringify(currency)}
                  value={currency.unit_name_short}
                  active={state.selectedCartCurrency?.unit_name_short === currency.unit_name_short}
                >
                  {currency.unit_name_short}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <small>{state.selectedCartCurrency?.unit_name_short}</small>
        </div>
      </div>
      <h4 className="mt-2 mb-3">
        {state.selectedCartCurrency?.unit_name_short || ''}{' '}
        {state?.pricebreakdown?.total}
      </h4>
        <div className="d-flex justify-content-between">
          <span>{t('cart.widget.subtotal')}</span>
          <span>
            {state.selectedCartCurrency?.unit_name_short || ''} {state?.pricebreakdown?.subToal}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <div>
          <span>{t('cart.widget.serviceFee')}</span>
          <img src={Info} ref={setTriggerRef} alt="info-icon" style={{width:"20px", margin:"0 4px"}}/>
          {visible && (
          <div
          
            ref={setTooltipRef}
            {...getTooltipProps({ className: 'tooltip-container', style:{width:"350px"} })}
          >
           {t('cart.widget.serviceFeeTip')}
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          </div>
        )}
          </div>
          <span>
            {state.selectedCartCurrency?.unit_name_short || ''} {state?.pricebreakdown?.serviceFee}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <span>{t('cart.widget.vat')}</span>
          <span>
            {state.selectedCartCurrency?.unit_name_short || ''} {state?.pricebreakdown?.VAT}
          </span>
        </div>

      {useGiftiGlobalPoints && lineValue && (
        <div className="d-flex justify-content-between">
          <span>{t('cart.widget.rewardPoints')}</span>
          <span>
            - {state.selectedCartCurrency?.unit_name_short || ''} {state?.pricebreakdown?.pointsUsed}
          </span>
        </div>
      )}
      <hr />
      <div className="d-flex justify-content-between mb-5">
        <span>
          <strong>{t('cart.widget.total')}</strong>
        </span>
        <span>
          <strong>
            {state.selectedCartCurrency?.unit_name_short || ''}{' '}
            {state?.pricebreakdown?.total}
          </strong>
        </span>
      </div>
      <div className={`d-flex flex-column justify-content-center align-content-between border border-2 ggp-parent-box rounded p-2 ${isRedeemable ? "mb-4" : ""}`}>
        {!authState.isAuthenticated && <Image src={exclamation} rounded style={{ width: '4%', height: '4%' }} />}
        {authState.isAuthenticated && (
          <div className="row d-flex align-items-baseline">
            <div className="m-3">
              <input
                type="checkbox"
                id="giftpoint-checkbox"
                disabled={rewardState.total_credits <= 0 || !isRedeemable}
                name="use giftipoints"
                onChange={() => {
                  setUseGiftiGlobalPoints(!useGiftiGlobalPoints);
                }}
                checked={useGiftiGlobalPoints}
              />
            </div>
            <label>{t('cart.widget.usePoints')}</label>
          </div>
        )}
        {!authState.isAuthenticated && (
          <p>
            <small>{t('cart.widget.loginOrSignup')}</small>
          </p>
        )}
        <div className="p-2 ggp-box mx-auto">
          <span className=" fs-6 text-center d-block">
            <small>{t('cart.widget.giftiGlobalPoints')}</small>
          </span>
          <span className="text-center d-block">
            {authState.isAuthenticated &&
              (Number(total_credits)-Number(state?.pricebreakdown?.creditUsed)).toFixed(2)}
          </span>
        </div>
      </div>
      {
        !isRedeemable ? <p className='text-danger mb-4'>{t('cart.errorMessage.pointsPurchase')}</p> : null
      }
      <div className="d-flex justify-content-around">
        {!authState.isAuthenticated && (
          <Button style={{padding:'10px 3rem'}}  type="button" variant="white" onClick={() => history.push('auth/login', { redirectTo: ROUTES.cart })}>
            {t('cart.widget.login')}
          </Button>
        )}
       { authState.isAuthenticated && <Button type="button" variant="persianGreen" onClick={checkout} disabled={!state.lineItems.length}>
          {t('cart.widget.checkout')}
        </Button>}
      </div>
      <Modals.SharedModal
        icon={CancelImage}
        header={t('cart.widget.modal.header')}
        subText={`${t('cart.widget.modal.subText')}.`}
        showModal={showOrderLimitModal}
        onModalClose={setShowOrderLimitModal}
      />
    </div>
  );
};

export default CartWidget;

CartWidget.propTypes = {
  authState: PropTypes.any,
  createCheckout: PropTypes.any,
  handleChangeCurrency: PropTypes.any,
  history: PropTypes.any,
  rewardState: PropTypes.any,
  state: PropTypes.any,
  useGiftiGlobalPoints : PropTypes.any,
  setUseGiftiGlobalPoints: PropTypes.any
};
