import React from "react";


function ArTerms() {
    return <div className="row my-4" dir='rtl'>
        <div className="col-md-8 t-col-left">
            <h1>شروط الاستخدام</h1>
            <button type="button" className="btn btn-primary btn-sm">
                تم التحديث الأخير: 11 نوفمبر 2024
            </button>

            <hr/>
            <article id="conwelcome">
                <h3> مرحبًا بكم في موقع www.giftiglobal.com ("الموقع الإلكتروني").</h3>
                <p>
                    مالك ومشغل الموقع هو شركة مايليست منطقة حرة ذ.م.م، وهي شركة ذات مسؤولية محدودة مسجلة في مدينة
                    الفجيرة الإعلامية الحرة برقم الرخصة التجارية 1722/2011 FCC، ويقع مكتبها المسجل في برج الفجيرة،
                    الإمارات العربية المتحدة (ص.ب 334555، دبي، الإمارات العربية المتحدة) (“<strong>مايليست</strong>”)،
                    وهي شركة ذات مسؤولية محدودة مسجلة في دولة الإمارات العربية المتحدة (“<strong>الإمارات العربية
                    المتحدة</strong>”).
                </p>
                <p>
                    تحدد شروط الاستخدام هذه جميع السياسات والشروط الإضافية (إن وجدت) المنشورة على الموقع من حين لآخر
                    الشروط التي تقدم لك بموجبها الوصول إلى واستخدام موقعنا وخدماتنا وتطبيقاتنا، بما في ذلك تطبيقاتنا على
                    الهواتف المحمولة (ويشار إليها مجتمعة بـ "<strong>الخدمات</strong>"). يمكنك العثور على جميع سياساتنا
                    والشروط الإضافية هنا:
                    <a href="https://www.giftiglobal.com/terms-and-conditions"><strong
                        style={{ color: '#467886' }}>https://www.giftiglobal.com/terms-and-conditions</strong></a> (“<strong>الوثائق
                    القانونية</strong>”).
                </p>
                <p>
                    تنطبق شروط الاستخدام هذه سواء كنت تتصفح موقعنا الإلكتروني من خلال نظام حاسوبي أو عبر جهاز محمول أو
                    باستخدام أي تطبيق أو منصة توفرها مايليست.
                </p>
                <p>
                    تُعتبر هذه الوثائق القانونية جزءًا لا يتجزأ من شروط الاستخدام هذه بالإشارة إليها.
                </p>
                <p>
                    من خلال الوصول إلى خدماتنا أو التسجيل فيها و/أو الاستمرار في استخدامها أو الوصول إليها، فإنك توافق
                    على الالتزام بشروط الاستخدام والوثائق القانونية هذه مع مفعول فوري. نخضع شروط الاستخدام والوثائق
                    القانونية هذه للتغيير من جانبنا في أي وقت دون أي التزام بإخطارك. استمرارك في استخدام الموقع بعد أي
                    تغيير يعتبر موافقتك على شروط الاستخدام والوثائق القانونية هذه المعدلة.
                </p>
                <p>
                    الإشارات في شروط الاستخدام هذه إلى “أنت”، أو “المستخدم”، أو “المشتري” (أو ما يشابه ذلك) هي إشارات
                    إليك كشخص أو كيان قانوني حسب مقتضى الحال.
                </p>
            </article>

            <article id="conabout">
                <h3>١. معلومات حول موقعنا</h3>
                <p>١-١ الموقع الإلكتروني عبارة عن منصة عالمية تتيح للشركات والأفراد والمؤسسات شراء بطاقات الهدايا الرقمية ("بطاقات الهدايا") وغيرها من المنتجات والخدمات المضافة من حين لآخر.</p>
                <p>١-٢ نحتفظ بالحق في تقديم خدمات جديدة وتحديث الخدمات أو سحب أيًا منها، وفقًا لتقديرنا الخاص دون أي التزام بإخطارك، ولن نصبح مسؤولين تجاهك عن ممارسة هذه السلطة التقديرية.</p>
            </article>

            <article id="eligibility-and-registration-requirements">
                <h3>٢. متطلبات الأهلية والتسجيل</h3>
                <p>٢-١ أنت مؤهل للتسجيل كمشتري والاستفادة من الخدمات إذا كنت تستوفي معايير الأهلية التالية:</p>
                <p>٢-٢ لقد تجاوزت السن القانوني لشراء المنتجات في بلد إقامتك؛ و</p>
                <p>٢-٣ يمكنك تقديم عنوانًا لبريد إلكتروني صالح لتسليم المنتجات.</p>
                <p>
                    ٢-٤ من أجل التسجيل في الموقع، تحتاج إلى تزويدنا بمعلومات معينة. ربما لا يُقبل تسجيلك في الموقع إذا لم تزودنا بالمعلومات المطلوبة. نحتفظ بالحق في رفض أي تسجيل دون مزيدًا من التوضيح. ونحتفظ بالحق أيضًا في إجراء مثل هذه الفحوصات اللازمة للتحقق من هويتك.
                </p>
                <p>٢-٥ بمجرد إكمال التسجيل بنجاح، يستمر تسجيلك لفترة غير محددة، مع إمكانية تعليق  حسابك أو إنهائه وفقًا للبند 8 من شروط الاستخدام هذه.</p>
                <p>
                    ٢-٦ تُستخدم معلوماتك لغرض الاتصال بك فيما يتعلق بطلبك/طلباتك. بعد الحصول على إذن صريح منك، نرسل إليك رسائل بريد إلكتروني حول منتجاتنا وتحديثات أخرى. إذا غيرت رأيك بعد الاشتراك، فيمكنك سحب موافقتك على اتصالنا بك، لمواصلة جمع معلوماتك أو استخدامها أو الافصاح عنها، في أي وقت، من خلال التواصل معنا على البريد الإلكتروني
                    {' '}<a href="mailto:care@giftiglobal.com">care@giftiglobal.com</a>.
                </p>
                <p>٢-٧ لمزيد من التفاصيل حول كيفية استخدامنا لبياناتك، نشجعك على مراجعة سياسة خصوصية البيانات المضمنة في الموقع.</p>
            </article>

            <article id="your-obligations">
                <h3>٣. الالتزامات الواقعة عليك</h3>
                <p>٣-١ عند استخدام الخدمات أو الوصول إليها، فإنك توافق على أنك:</p>
                <ul>
                    <li>مسؤولًأ عن الحفاظ على سرية حسابك وكلمة المرور وتقييد الوصول إليهما واستخدامهما، وتقبل المسؤولية عن جميع الأنشطة التي تحدث تحت حسابك وكلمة المرور؛</li>
                    <li>توافق على إخطارنا فورًا بأي استخدام غير مصرح به لكلمة مرورك أو حسابك أو أي خرق آخر للأمن؛</li>
                    <li>ستقدم معلومات حقيقية ودقيقة وحديثة وكاملة عن نفسك واستخدامك للخدمات حسبما نطلبه؛</li>
                    <li>لن تفصح لأي طرف أخر (باستثناء ما نطلبه) عن معلومات المستخدم المقدمة لك؛ و</li>
                    <li>ستتعاون مع طلباتنا للحصول على معلومات إضافية فيما يتعلق بأهليتك واستخدامك لخدماتنا.</li>
                    <li>
                        ستلتزم بشروط الاستخدام التالية:
                        <ul className="arrow-sublist">
                            <li>إعادة البيع غير المصرح به: أنت توافق على عدم إعادة بيع بطاقات الهدايا أو استبدالها أو نقلها مقابل العملات المشفرة أو أي شكل آخر من الأصول الرقمية التي لا تعتبر عملة قانونية.</li>
                            <li> الاسترداد مقابل النقود: لا يجوز استبدال بطاقات الهدايا مقابل النقود، سواء جزئيًا أو كليًا، إلا وفقًا لما يقتضيه القانون المعمول به. يُحظر تمامًا أي محاولة لتحويل بطاقات الهدايا إلى نقود من خلال وسائل غير مصرح بها.
                            </li>
                            <li> الاستخدام في الأنشطة غير القانونية: لن تستخدم أو تسمح باستخدام بطاقات الهدايا لأي غرض غير قانوني أو احتيالي أو مخالف لأي قوانين أو لوائح سارية. ويشمل هذا، على سبيل المثال لا الحصر، غسل الأموال أو تمويل الإرهاب أو الأنشطة الإجرامية الأخرى.
                            </li>
                            <li> المعاملات الاحتيالية: أنت توافق على تنفيذ التدابير المناسبة لمنع استخدام بطاقات الهدايا في المعاملات الاحتيالية. ينبغي الإبلاغ عن أي سوء استخدام، بما في ذلك استخدام بطاقات الهدايا المسروقة أو المزيفة، إلى مايليست على الفور، ويتعاون العميل بشكل كامل في أي تحقيق لاحق.
                            </li>
                            <li>قيود التسويق والترويج: لا يجوز لك استخدام بطاقات الهدايا في أي أنشطة تسويقية أو إعلانية أو ترويجية لم تتم الموافقة عليها صراحةً وكتابيًا من قبل مايليست. ويشمل هذا، على سبيل المثال لا الحصر، استخدام بطاقات الهدايا كحوافز أو مكافآت أو هدايا مجانية بطريقة قد تقلل من قيمة أو العلامة التجارية لبطاقات الهدايا أو مايليست.
                            </li>
                            <li>الامتثال للشروط والأحكام: التأكد من أنك على علم بشروط وأحكام بطاقات الهدايا والامتثال لها. ويشمل هذا أي قيود على استخدام بطاقات الهدايا وانتهاء صلاحيتها واستردادها.
                            </li>
                            <li> عواقب الانتهاك: في حالة انتهاكك لأي من الاستخدامات المحظورة المذكورة أعلاه، يحق لشركة مايليست اتخاذ إجراءات تصحيحية، بما في ذلك على سبيل المثال لا الحصر تعليق أو إلغاء حسابك وبطاقات الهدايا، وحجب الدفعات، وإنهاء هذه الاتفاقية.
                            </li>
                            <li>الإخطار والتعاون: تُخطر أنت مايليست على الفور بأي استخدامات محظورة معروفة أو مشتبه بها لبطاقات الهدايا. أنت توافق على التعاون الكامل مع مايليست في التحقيق في أي من هذه المشكلات وحلها.
                            </li>
                            <li> الاستخدام كدفعة مقابل سلع/خدمات غير مصرح بها: لا يجوز استخدام بطاقات الهدايا كدفعة مقابل سلع أو خدمات تقدمها كيانات أو أفراد غير مصرح لهم من قبل مايليست. يحظر أي محاولة لاستخدام بطاقات الهدايا في معاملات غير مصرح بها.
                            </li>
                            <li> الشراء من أطراف أخرى غير مصرح بها: لا يجوز لك شراء بطاقات الهدايا من أطراف أخرى غير مصرح بها. لا يمكن لـ مايليست ضمان شرعية بطاقات الهدايا التي تم الحصول عليها من مثل هذه المصادر، ويحظر استخدام بطاقات الهدايا هذه.
                            </li>
                            <li> الاستخدام للحصول على المخزون: لا يجوز لك استخدام بطاقات الهدايا لإتمام المبيعات أو تمويل المشتريات بقصد إعادة بيع أو تصدير السلع أو الخدمات. تُعد بطاقات الهدايا مخصصة للاستخدام الشخصي وليس للأغراض التجارية.
                            </li>
                            <li> السلع والخدمات غير المؤهلة: لا يجوز استخدام بطاقات الهدايا لشراء سلع وخدمات غير مؤهلة معينة، مثل بطاقات الهدايا الأخرى أو العناصر القابلة للتجميع. قد تخضع قائمة العناصر غير المؤهلة للتغيير وفقًا لتقدير مايليست وحدها.
                            </li>
                            <li> النقل بعد الاسترداد: بمجرد استرداد رمز المطالبة ببطاقة الهدايا، لا يمكن نقلها إلى حساب آخر. يُحظر تمامًا استرداد بطاقة الهدايا المخصصة لمستلم آخر.
                            </li>
                            <li> القوة القاهرة: لن تكون مايليست مسؤولة عن أي اخفاق في معالجة بطاقات الهدايا أو تنفيذها نتيجة لظروف خارجة عن إرادتها، بما في ذلك على سبيل المثال لا الحصر، الكوارث الطبيعية، الحروب، الأعمال الإرهابية، اللوائح الحكومية، أو غيرها من الأحداث غير المتوقعة. في مثل هذه الحالات، قد يُعلق تنفيذ معاملات بطاقات الهدايا مؤقتًا حتى استئناف العمليات الطبيعية.
                            </li>
                        </ul>
                    </li>
                </ul>
                <p>٣-٢ عند استخدام الخدمات أو الوصول إليها، فإنك توافق على عدم:
                </p>
                <ul>
                    <li>انتهاك أو التحايل على أي قوانين أو حقوق طرف ثالث أخرى أو أنظمتنا أو سياساتنا أو قراراتنا بشأن حالة حسابك؛
                    </li>
                    <li>استخدام خدماتنا إذا لم تعد تستوفي معايير الأهلية أو لم تكن قادرًا على إبرام عقود ملزمة قانونًا، أو تم تعليقك مؤقتًا أو إلى أجل غير مسمى من استخدام خدماتنا؛
                    </li>
                    <li>عدم سداد ثمن العناصر التي اشتريتها، ما لم يكن لديك سبب وجيه كما هو منصوص عليه في أي من سياساتنا؛
                    </li>
                    <li>استخدم أي بطاقات خصم أو ائتمان مسروقة لسداد ثمن مشترياتك؛
                    </li>
                    <li>استخدام معلومات الاتصال المقدمة لك أثناء إجراء معاملة على الموقع لطلب مبيعات إضافية دون اتصال بالإنترنت أو على موقع إلكتروني آخر؛
                    </li>
                    <li>التلاعب بسعر أي سلعة؛
                    </li>
                    <li>التدخل في قوائم أي مستخدم آخر؛
                    </li>
                    <li>اتخاذ أي إجراء قد يضر بأنظمة التقييم والمراجعات في الموقع؛
                    </li>
                    <li>نشر محتوى كاذب أو غير دقيق أو مضلل أو مخادع أو تشهيري أو ما شابه ذلك؛
                    </li>
                    <li>نقل حسابك إلى طرف آخر دون الحصول على موافقة خطية مسبقة منا؛
                    </li>
                    <li>توزيع أو نشر رسائل البريد الإلكتروني غير المرغوب فيها أو الاتصالات الإلكترونية بكميات كبيرة أو ما شابه ذلك؛
                    </li>
                    <li>توزيع الفيروسات أو أي تقنيات أخرى قد تضر بخدماتنا أو مصالح أو ممتلكات المستخدمين الآخرين؛
                    </li>
                    <li>
                        انتهاك: حقوق الطبع والنشر والعلامة التجارية وبراءات الاختراع والدعاية والأخلاق وقاعدة البيانات و/أو حقوق الملكية الفكرية الأخرى (يٌشار إليها بشكل جماعي،"حقوق الملكية الفكرية") التي تخصنا أو مرخصة لنا؛ أو أي حقوق ملكية فكرية تخص أي طرف ثالث؛
                    </li>
                    <li>جمع أو الحصول على معلومات حول المستخدمين دون موافقتهم؛ أو
                    </li>
                    <li>التحايل على أي تدابير تقنية نستخدمها لتوفير الخدمات.
                    </li>
                </ul>

            </article>

            <article id="customer-restrictions-and-responsibilities">
                <h3>٤. القيود والمسؤوليات الخاصة بالعملاء في جيفتي جلوبال</h3>
                <p>٤-١ مسؤوليات العميل والقيود: يوافق العميل على استخدام برامج وخدمات جيفتي جلوبال وفقًا لجميع القوانين واللوائح المعمول بها. لا يجوز للعميل تعديل أو توزيع أو ترخيص من الباطن أو إجراء هندسة عكسية للبرامج التي توفرها جيفتي جلوبال. كما يتحمل العميل مسؤولية الحفاظ على أمان أنظمته، بما في ذلك حماية معلومات الحساب وكلمات المرور.
                </p>
                <p>٤-٢  يُحظر تمامًا الاستخدام غير المصرح به للبرنامج، بما في ذلك محاولة الوصول إلى الكود المصدر أو إنشاء أعمال مشتقة منه. يحق لنا تعليق أو إنهاء الوصول إلى الخدمات إذا انتهكت أو انتهك العميل هذه القيود، وسيتحمل العميل مسؤولية أي تكاليف أو أضرار مرتبطة بذلك.
                </p>
                <p>٤-٣ تقر وتوافق على أن أي خرق لهذا البند 4 يمنح مايليست الحق في إنهاء هذه الاتفاقية بالكامل دون إشعار مسبق. علاوة على ذلك، يحق لشركة مايليست متابعة أي وجميع الحلول المتاحة بموجب القانون المعمول به ردًا على مثل هذا الخرق.
                </p>
            </article>

            <article id="intellectual-property-rights">
                <h3>٥. حقوق الملكية الفكرية</h3>
                <p>باستثناء الحقوق الممنوحة صراحةً بموجب شروط الاستخدام هذه:
                </p>
                <ul>
                    <li>
                        نحتفظ بجميع حقوق الملكية الخاصة بالبرنامج والوثائق وأي مواد أخرى مقدمة بموجب هذه الاتفاقية. كل المحتوى المضمن فالموقع الإلكتروني، بما في ذلك على سبيل المثال لا الحصر النصوص والرسومات والشعارات والصور والمقاطع الصوتية والتنزيلات الرقمية والبرامج هي ملكيتنا أو ملك مرخصينا. نحن (أو مرخصونا، حسب مقتضى الحال) نحتفظ بكل الحقوق والممتلكات والمصلحة في الموقع والخدمات، بما في ذلك على سبيل المثال لا الحصر، جميع حقوق الملكية الفكرية فيها؛ و
                    </li>
                    <li>
                        تصبح جميع الحقوق والملكية والمصلحة في أي معلومات أو مواد أو محتوى آخر تقدمها فيما يتعلق باستخدامك للخدمات، بما في ذلك جميع حقوق الملكية الفكرية الواردة فيها، ملكيتنا.
                    </li>
                    <li>
                        توافق على أنه ليس لديك الحق في استخدام أي من علاماتنا التجارية أو حقوق الملكية الفكرية الخاصة بنا أو بأي جهة مرخصة لنا دون موافقتنا الخطية المسبقة.
                    </li>
                    <li>
                        جميع الحقوق غير الممنوحة لك صراحةً في شروط الاستخدام هذه محفوظة لنا أو لجهات الترخيص لدينا.
                    </li>
                    <li>
                        يجوز لنا استخدام أي تعليقات أو اقتراحات يقدمها العميل فيما يتعلق بالبرنامج أو الخدمات دون أي التزام تجاه العميل، وسنمتلك حقوق الملكية الفكرية الناشئة عن مثل هذه التعليقات.
                    </li>
                </ul>
            </article>

            <article id="warranties-representations-undertakings">
                <h3>٦. الضمانات والإقرارات والتصريحات</h3>
                <p>تضمن وتقر وتتعهد بما يلي:</p>
                <ul>
                    <li>تلتزم بالكامل وتستمر في الالتزام الكامل في جميع الأوقات بجميع القوانين واللوائح والأنظمة المعمول بها، بما في ذلك على سبيل المثال لا الحصر، جميع قوانين الخصوصية وتنظيم المحتوى؛
                    </li>
                    <li>أي قوانين أو قواعد أو لوائح أو إرشادات حكومية تخضع لها؛ أو
                    </li>
                    <li>أي اتفاقيات أخرى تكون طرفًا فيها أو تكون ملزمًا بها بطريقة أخرى.
                    </li>
                    <li> مع مراعاة البند 3، إذا قمت بإنشاء أو استخدام حساب نيابة عن كيان تجاري، فإنك تمثل أنك مفوض بالتصرف نيابة عن هذا الكيان وتلزم الكيان بهذه الشروط والأحكام. ويُعتبر هذا الحساب مملوكًا للكيان التجاري ومتحكمًا به.
                    </li>
                    <li>مع مراعاة البند 6 تُقدم الخدمات لك على أساس "كما هي" دون أي إقرارات أو ضمانات أو شروط من أي نوع. نحن نُخلي مسؤوليتنا من جميع الضمانات والشروط والتمثيلات من أي نوع، سواء كانت صريحة أو ضمنية أو تكميلية، بما في ذلك، على سبيل المثال لا الحصر، جميع الشروط والإقرارات أو الضمانات المتعلقة بالقابلية للتسويق، أو الملاءمة لغرض معين أو عام، أو عدم الانتهاك، أو التوافق، أو أن الخدمات آمنة أو خالية من الأخطاء، أو ستعمل دون انقطاع، أو ستقدم في الوقت المناسب أو بشكل صحيح أو على الإطلاق.
                    </li>
                    <li>علاوة على ذلك، في حين نحاول أن نكون دقيقين قدر الإمكان، فإننا لا نضمن أن تكون أوصاف المنتجات أو أي محتوى آخر لأي خدمة دقيقة أو كاملة أو موثوقة أو حديثة أو خالية من الأخطاء. بالإضافة إلى ذلك، بصفتك مشتريًا، المستخدم أو العميل، فإنك توافق على أننا لسنا مسؤولين عن فحص أو ضمان القوائم أو المحتوى الذي نقدمه نحن أو أطراف أخرى من خلال الخدمات، وأنك لن تحاول تحميلنا المسؤولية عن أي أخطاء أو عيوب في أي من القوائم.
                    </li>
                </ul>
            </article>

            <article id="liability-indemnities">
                <h3>٧. المسؤولية والتعويضات</h3>
                <p>٧-١  لا شيء في شروط الاستخدام هذه يحد أو يستبعد مسؤولية أي طرف:
                </p>
                <ul>
                    <li>للاحتيال، بما في ذلك التصريح الاحتيالي، الذي ارتكبه هذا الطرف؛
                    </li>
                    <li>للوفاة أو الإصابة الشخصية الناجمة عن إهمال هذا الطرف؛ أو
                    </li>
                    <li>لأي مسؤولية أخرى لا يمكن الحد منها أو استبعادها بموجب القانون المعمول به.
                    </li>
                </ul>
                <p>٧-٢ مع مراعاة البند 7-1، في أي حال من الأحوال، لن نكون، نحن أو شركاتنا الأم أو الشركات التابعة أو الشريكة، ومديرينا أو موظفينا أو وكلائنا أو موظفينا أو موردينا أو المقاولين من الباطن أو المرخصين لنا، مسؤولين، سواء استند ذلك إلى دعوى أو مطالبة في العقد أو الضرر أو الإهمال أو خرق الواجب القانوني أو غيره، الناشئة عن أو المتعلقة بشروط الاستخدام هذه عن فقدان الأرباح أو فقدان البيانات أو المعلومات أو انقطاع العمل أو أي خسائر مالية أخرى أو عن أي أضرار خاصة أو غير مباشرة أو عرضية أو تبعية، حتى إذا تم إعلامنا أو شركاتنا التابعة أو مديرينا أو موظفينا أو وكلائنا أو موظفينا أو المرخصين أو المقاولين الباطن أو الموردين بإمكانية حدوث مثل هذه الأضرار.
                </p>
                <p>٧-٣ بالإضافة إلى ذلك، إلى الحد الذي تسمح به القوانين المعمول بها، فإننا (بما في ذلك شركاتنا الأم والشركات التابعة والشريكة ومديرينا وموظفينا ووكلائنا وموردينا والمقاولين الفرعيين أو المرخصين لدينا) غير مسؤولين، وتوافق على عدم تحميلنا المسؤولية، عن أي أضرار أو خسائر ناجمة بشكل مباشر أو غير مباشر عن:
                </p>
                <ul>
                    <li>المحتوى أو المعلومات الأخرى التي تقدمها عند استخدام الخدمات؛
                    </li>
                    <li>استخدامك لخدماتنا أو عدم قدرتك على استخدامها؛
                    </li>
                    <li>التسعير، والشحن، والتنسيق أو أي إرشادات أخرى نقدمها؛
                    </li>
                    <li>التأخيرات أو الانقطاعات في خدماتنا؛
                    </li>
                    <li>الفيروسات أو البرامج الضارة الأخرى التي تم الحصول عليها عن طريق الوصول إلى خدماتنا أو الارتباط بها؛
                    </li>
                    <li>العيوب أو الأخطاء أو عدم الدقة من أي نوع في خدماتنا؛
                    </li>
                    <li>الضرر الذي يلحق بجهازك نتيجة لاستخدام المنتجات المباعة على الويبسالموقع أو خدماتنا؛
                    </li>
                    <li>الضرر الذي يلحق بجهازك نتيجة استخدام المنتجات المباعة على الموقع الإلكتروني أو خدماتنا؛
                    </li>
                    <li>تعليق أو أي إجراء آخر اتخذناه فيما يتعلق باستخدامك للخدمات.
                    </li>
                </ul>
                <p>٧-٤  مع مراعاة البند 7-1، إذا اعتُبر البندين 7-2 أو 7-3 غير قابلين للتنفيذ أو التطبيق لأي سبب، فإن المسؤولية الإجمالية المطبقة علينا، نحن أو شركاتنا الأم أو الشركات التابعة أو الشريكة ومديرينا أو موظفينا أو وكلائنا أو موردينا أو المقاولين من الباطن أو المرخصين لدينا، تجاهك، سواء كانت استنادًا إلى دعوى أو مطالبة في العقد أو الإهمال أو خرق الواجب القانوني أو غيره، الناشئة عن أو المتعلقة بشروط الاستخدام هذه، تقتصر على مبلغ الرسوم التي سددتها لنا في الاثني عشر (12) شهرًا السابقة على الإجراء الذي أدى إلى المسؤولية.
                </p>
                <p>٧-٥ توافق على تعويضنا وإعفائنا، نحن وشركاتنا الأم والشركات التابعة والشريكة ومديرينا وموظفينا ووكلائنا وموردينا والمقاولين من الباطن أو المرخصين لدينا، من أي خسائر أو أضرار أو نفقات (بما في ذلك أتعاب المحاماة) (“المطالبات”) الناشئة عن أو المتعلقة بـ:
                </p>
                <ul>
                    <li> أي مطالبات أو طلبات مقدمة من أي من الجهات المرخصة لدينا أو الطرف الأخر بسبب أو نتيجة لاستخدامك للخدمات؛
                    </li>
                    <li>انتهاكك لأي من أحكام شروط الاستخدام هذه، بما في ذلك على سبيل المثال لا الحصر، أي من الضمانات والإقرارات والتعهدات؛
                    </li>
                    <li>انتهاكك لأي قوانين سارية، بما في ذلك على سبيل المثال لا الحصر، قوانين حماية البيانات أو قوانين مكافحة البريد العشوائي؛ أو
                    </li>
                    <li> الطريقة التي تستخدم بها خدماتنا، بما في ذلك، على سبيل المثال لا الحصر، أن المحتوى الذي تنشره (إن وجد)، أو العناصر التي تسردها أو تشتريها، أو علاماتك التجارية تنتهك حقوق الملكية الفكرية لأي طرف أخر، أو أن محتوى مشترياتك أو القوائم يشكل تشهيرًا أو افتراءً أو فاحشًا أو ينتهك أي حقوق أخرى (بما في ذلك حقوق الخصوصية) لأي طرف أخر (بما في ذلك مستخدمي الموقع الآخرين).
                    </li>
                </ul>
            </article>

            <article id="suspension-termination-cancellation">
                <h3>٨. تعليق الأعمال والإنهاء والإلغاء
                </h3>
                <p>٨-١ دون المساس بأي من حقوقنا أو سبل انتصافنا ودون تحمل أي مسؤولية تجاهك، يجوز لنا تقييد أو تعليق أو سحب وصول المستخدم إلى الخدمات، و/أو إلغاء أي طلب لمنتج (منتجات) وفقًا لتقديرنا وحدنا دون الالتزام بإرسال إشعار مسبق. لتجنب الشك، إذا تم إلغاء أي طلب سيتم استرجاع أي مبالغ مُسددة وتسلمناها فيما يتعلق بطلب منتج (منتجات) ملغى. وجميع الطلبات التي قدمتها والتي قررنا عدم الوفاء بها لأي سبب من الأسباب وسوف نقوم بإبلاغك بالأسباب من خلال معلومات الاتصال المسجلة لديك.
                </p>
                <p>٨-٢ دون التأثير على أي حقوق أو سبل انتصاف أخرى قد تتمتع بها شركة مايليست، يجوز لشركة مايليست تعليق أو إنهاء حسابك واستخدامك للموقع الإلكتروني في حالة:
                </p>
                <ul>
                    <li>انتهاك شروط الاستخدام هذه أو أي من سياسات شركة مايليست.
                    </li>
                    <li>اشتباه شركة مايليست بناءً على أسباب معقولة في أنك قد ترتكب خرقًا لشروط الاستخدام هذه.
                    </li>
                    <li>مشاركتك في أي نشاط مخالف للقانون.
                    </li>
                    <li>القيام بأي شيء يؤثر سلبًا على أعمال شركة مايليست أو سمعتها.
                    </li>
                    <li>اعتبار شركة مايليست أنك تقوم بإزعاج أو تعطيل أو مقاطعة تشغيل الموقع بأي شكل من الأشكال.
                    </li>
                    <li>اعتبار شركة مايليست أنك تشكل خطرًا للاحتيال، أو تستخدم حسابات متعددة، أو تستخدم عناوين بروتوكول الإنترنت الوكيلة لإخفاء استخدام حسابات متعددة.
                    </li>
                    <li>اعتبار شركة مايليست أنه من الضروري، لأي سبب من الأسباب، حماية شركة مايليست أو المستخدمين الآخرين للموقع.
                    </li>
                </ul>
                <p>٨-٣ يمكنك إلغاء تسجيل حسابك والتوقف عن استخدام هذا الموقع في أي وقت. كما أن شركة مايليست ستحتفظ بحسابك وكل المحتوى الخاص بك في وضع غير نشط ولكن قد لا يتم حذفه بالضرورة. إذا اختارت شركة مايليست وفقًا لتقديرها الخاص حذف كل المحتوى الخاص بك أو حسابك بشكل دائم بعد إلغاء تسجيل حسابك أو لأي سبب آخر (بما في ذلك فترة عدم النشاط من جانبك)، فلن تتمكن من استرداده أو الوصول إليه مرة أخرى ولن تتحمل شركة مايليست أي مسؤولية عن هذا.
                </p>
                <p>٨-٤ دون المساس بأي من حقوق شركة مايليست الأخرى أو سبل انتصافها بموجب شروط الاستخدام هذه أو القانون، قد تختار شركة مايليست اتخاذ خطوات تقنية و/أو قانونية أخرى ضد أي شخص أو مستخدم يتسبب في حدوث مشكلات أو مسؤوليات قانونية محتملة من أي نوع، أو ينتهك التصرف بشكل غير متسق مع شروط الاستخدام هذه أو أي قانون ذي صلة.
                </p>
            </article>

            <article id="reporting-violations">
                <h3>٩. الإبلاغ عن انتهاكات شروط الاستخدام هذه
                </h3>
                <p>٩-١ نلتزم بضمان أن العناصر والمحتوى المدرج على موقعنا الإلكتروني هذا يتوافق مع شروط الاستخدام هذه. إذا كنت تعتقد أن أحد العناصر أو المحتوى المدرج ينتهك شروط الاستخدام هذه، فيرجى إخطارنا بالتفاصيل المذكورة أدناه وسوف نقوم بالتحقيق.
                </p>
                <p>٩-٢ معلومات الاتصال بنا:
                </p>
                <ul>
                    <li>العنوان: الفجيرة، برج الفجيرة، الإمارات العربية المتحدة (ص.ب: 334555، دبي، الإمارات العربية المتحدة)
                    </li>
                    <li> معرف البريد الإلكتروني: <a href="mailto:care@giftiglobal.com">care@giftiglobal.com</a> نسخة إلى: <a
                        href="mailto:legal@meritincentives.com">legal@meritincentives.com</a></li>
                    <li>رقم الهاتف: +97145621020</li>
                </ul>
            </article>

            <article id="affiliates-additional-functions">
                <h3>١٠. الشركات التابعة والوظائف الإضافية لـ giftiglobal.com
                </h3>
                <p>١٠-١ توفر لك giftiglobal.com من مايليست منطقة حرة ذ.م.م و/أو الشركات التابعة لها ("الشركات التابعة لمايليست") ميزات الموقع الإلكتروني والمنتجات والخدمات الأخرى عند استخدامك أو تسجيلك كمشتري أو عميل أو مستخدم على الموقع. يُقصد "بالشركات التابعة"، فيما يتعلق بشخص معين، أي كيان يتحكم بشكل مباشر أو غير مباشر، أو يخضع لسيطرة هذا الشخص، أو يكون تحت سيطرة مشتركة معه. لتجنب ذلك، تتضمن الشركات التابعة لمايليست جميع الشركات والفروع والشركات التابعة والزميلة لشركة مايليست منطقة حرة ذ.م.م.
                </p>
                <p>١٠-٢ لتعزيز تجربتك عبر الموقع الإلكتروني ومع الشركات التابعة لمايليست، فإنك توافق بموجبه على أنه يجوز لنا إعداد خدمات ووظائف و/أو حسابات إضافية نيابة عنك، باستخدام المعلومات التي تقدمها لنا على الموقع.
                </p>
            </article>

            <article id="gift-card-information">
                <h3>١١. معلومات بطاقة الهدايا</h3>
                <p>١١-١ تخضع أي معلومات مدرجة في موقعنا الإلكتروني أو نتيجة للزيارات التي تقوم بها لشروطنا وأحكامنا الخاصة ببطاقة الهدايا ذات الصلة وشروط الاستخدام المدرجة بموجبه، لتجنب الشك، في حالة وجود أي تناقضات بين شروط وأحكام بطاقة الهدايا وشروط الاستخدام هذه، تسود شروط بطاقة الهدايا التي اشتريتها أو تنوي شراءها.
                </p>
                <p>١١-٢ بطاقات الهدايا المرسلة عبر البريد الإلكتروني بواسطة giftiglobal.com ليست لإعادة البيع. يحتفظ giftiglobal.com بالحق في تغيير نطاق بطاقات الهدايا المقدمة في أي وقت، دون إرسال إشعار مسبق. لا يتحمل موقع giftiglobal.com أي مسؤولية تجاه التغييرات التي تطرأ على المتاجر التي تقبل أي بطاقة هدايا، وعن أي تغييرات تطرأ على الشروط والأحكام المتعلقة بكل بطاقة هدية على حدة. يتعين على المستخدمين الرجوع إلى الجزء الخلفي من بطاقة الهدية المحددة لمعرفة الشروط والأحكام الخاصة بأي بائع تجزئة أو متجر، ولا يتحمل موقع giftiglobal.com أي مسؤولية للتغييرات وفقاً لشروط وأحكام الجهات المصدرة لبطاقات الهدايا الفردية.
                </p>
                <p>١١-٣ ينقل موقع giftiglobal.com المخاطر والملكية إلى العميل أو المشتري أو المستخدم عند التسليم إلى عنوان البريد الإلكتروني المسجل أو أي طريقة أخرى للإتمام. لا يتحمل موقع giftiglobal.com أي مسؤولية عن أي فقدان أو سرقة أو تلف لبطاقات الهدايا بمجرد انتقال مسؤولية الملكية إلى العميل في وقت التسليم. لا يرتبط موقع giftiglobal.com بشكل مباشر ببعض تجار التجزئة الذين يتم إدراج منتجاتهم في مجموعتنا. في حالة وضع أي من هذه المنتجات أو الشركات تحت الإدارة أو الحراسة القضائية في موقعنا الإلكتروني، لا يوجد التزام على موقع giftiglobal.com أو مايليست باستبدال أو تبديل أي بطاقات هدايا غير منفقة تم إصدارها مسبقًا.
                    </p>
            </article>

            <article id="payment-methods-and-protecting-your-security">
                <h3>١٢. طرق الدفع وحماية بياناتك
                </h3>
                <p>١٢-١ سيتم معالجة جميع المعاملات بالدرهم الإماراتي أو الريال السعودي أو الدولار الأمريكي أو اليورو، ونقبل طرق دفع متعددة للطلبات عبر الإنترنت مثل فيزا وماستر كارد. سيتم فرض رسوم معالجة على الدفع من خلال بطاقات الخصم أو الائتمان، وسيتحمل العميل هذه التكلفة إلى جانب أي رسوم مصرفية إضافية أخرى.
                </p>
                <p> في حالة الدفع عن طريق التحويل البنكي، يتحمل العميل/ العميل مسؤولية سداد جميع الرسوم المصرفية ورسوم البنك الوسيط ورسوم التحصيل وأي رسوم أخرى يفرضها البنك المحول أو المستفيد.
                </p>
                <p>١٢-٢  تكون جميع الطلبات مدفوعة مسبقًا؛ ولا يمكنك طلب بطاقات الهدايا بالائتمان. يجب على giftiglobal.com استلام الدفعة كاملة قبل استكمال بطاقات الهدايا
                    .</p>
                <p>١٢-٣ لا نحتفظ برقم بطاقتك الائتمانية، بل يتم تمرير هذه المعلومات مباشرة إلى مزود بوابة الدفع لدينا لغرض معالجة دفعتك.
                </p>
                <p>١٢-٤ عندما تقوم بإجراء عملية شراء باستخدام موقعنا الإلكتروني، فإنك تقر لنا بأن (أ) أي معلومات بطاقة ائتمان أو بطاقة خصم تقدمها صحيحة وكاملة، (ب) سيتم قبول الرسوم التي تكبدتها من قبل شركة بطاقة الائتمان/الخصم الخاصة بك، و(ج) ستدفع الرسوم التي تكبدتها بالأسعار المعلنة، بما في ذلك أي ضرائب سارية.
                </p>
            </article>

            <article id="fulfillment-of-gift-cards-specific-clause">
                <h3>١٣. معالجة بطاقات الهدايا- بند محدد</h3>
                <p>١٣.١ جميع الطلبات المقدمة على giftiglobal.com سيتم معالجتها بالكامل بعد استلام الدفع. سنقوم بمعالجة الطلب وإرساله إلى عنوان البريد الإلكتروني المسجل."
                </p>
            </article>

            <article id="general">
                <h3>١٤. - أحكام عامة
                </h3>
                <p>١٤-١ القانون الحاكم. تخضع شروط الاستخدام هذه وأي حقوق أو التزامات غير تعاقدية ناشئة عنها أو فيما يتصل بها لقوانين دولة الإمارات العربية المتحدة، كما هي مطبقة في إمارة دبي، ويتم تفسيرها وفقًا لها.
                </p>
                <p>١٤-٢ حل النزاعات. إذا كانت لديك مشكلة مع خدماتنا، يرجى الاتصال بنا بشأن التفاصيل الواردة في البند 9سنبذل قصارى جهدنا لحل مشكلتك في أقرب وقت ممكن. سيتم إحالة أي نزاعات أو مطالبات تنشأ عن أو فيما يتعلق بشروط الاستخدام هذه، بما في ذلك أي حقوق أو التزامات غير تعاقدية تنشأ عن أو فيما يتعلق بشروط الاستخدام هذه، إلى محاكم دبي وحلها نهائيًا.
                </p>
                <p>١٤-٣ حقوق الطرف الثالث. لا يحق لأي شخص ليس طرفًا في شروط الاستخدام هذه فرض أي من شروطها.
                </p>
                <p>١٤-٤ العلاقة بين الطرفين. لا يجوز للأطراف أو أي طرف ثالث اعتبار أو تفسير أي شيء وارد في شروط الاستخدام هذه على أنه ينشئ علاقة شراكة أو مشروع مشترك أو وكالة بين الطرفين، مع العلم أن الطرفين سيظلان في جميع الأوقات طرفين مستقلين يتعاقدان على الخدمات.
                </p>
                <p>١٤-٥ ضمانات إضافية. يتعهد الطرفان بتنفيذ أو ترتيب تنفيذ كل عمل أو وثيقة أو شيء ضروري اللازم لتنفيذ وتطبيق شروط الاستخدام هذه إلى أقصى حد ممكن، بما في ذلك، على سبيل المثال لا الحصر، مساعدة بعضهما البعض في الامتثال للقانون الساري.
                </p>
                <p>١٤-٦ التنازل. تعتبر شروط الاستخدام هذه ملزمة وتضمن مصلحة الأطراف وخلفائهم والمتنازل لهم المسموح لهم. حيث أنك توافق على أنك لن تقوم بالتنازل عن شروط الاستخدام هذه أو أي من حقوقك أو التزاماتك بموجب شروط الاستخدام هذه، سواء بشكل مباشر أو غير مباشر، دون الحصول أولاً على موافقتنا الكتابية المسبقة، على ألا يتم حجب هذه الموافقة بشكل غير معقول.
                </p>
                <p>	على الرغم مما ورد أعلاه، فإنك تقر وتوافق على أنه يجوز لنا تكرار أو تخصيص أو تجديد أو نقل حقوقنا والتزاماتنا بموجب شروط الاستخدام هذه إلى أي شركة تابعة أو فرع أو شركة أم لنا دون التزام بإخطارك، وفي حالة مثل هذا التنازل، فلن نكون مسؤولين عن أي التزام منصوص عليه في شروط الاستخدام هذه و/أو عن أي خرق لشروط الاستخدام هذه. يتعين على المحول إليه أو المحال إليه اعتبارًا من تاريخ هذا التنازل، أن يتولى وينفذ جميع التزاماتنا المنصوص عليها في شروط الاستخدام هذه، وتوافق على الاعتراف بالمحول إليه أو المحال إليه وقبوله بدلاً منا لجميع الأغراض بموجب شروط الاستخدام هذه.
                </p>
                <p>١٤-٧ مجمل الاتفاقية. تحتوي شروط الاستخدام هذه والمستندات المشار إليها أو المدرجة بموجبه بالإشارة إليها على الاتفاقية الكاملة بين الطرفين فيما يتعلق بالموضوع وتحل محل جميع الاتفاقيات والمفاوضات والتصريحات السابقة، المكتوبة أو الشفهية، المتعلقة بموضوعها. باستثناء ما هو منصوص عليه في شروط الاستخدام هذه والمستندات المشار إليها أو المدرجة في شروط الاستخدام هذه بالإشارة إليها. لا توجد شروط أو تصريحات أو ضمانات أو تعهدات أو اتفاقيات بين الطرفين سواء كانت مباشرة أو غير مباشرة أو جانبية أو صريحة أو ضمنية.
                </p>
                <p>١٤-٨ التعديل. لا يجوز لك بأي حال من الأحوال تعديل أو تغيير أو تنقيح أو استكمال شروط الاستخدام هذه. يحق لنا تعديل أو تغيير أو تنقيح أو استكمال شروط الاستخدام هذه في أي وقت ومن وقت لآخر. سنقوم بنشر النسخة الحالية من شروط الاستخدام هذه على الموقع وسيصبح كل تغيير ساريًا عند نشره على الموقع أو عند التاريخ الذي نحدده باعتباره "تاريخ النفاذ" (إن وجد). يشكل استمرارك في استخدام الخدمات بعد أي تغيير من هذا القبيل موافقتك على الالتزام بهذه الشروط المعدلة وقبولك لها.
                </p>
                <p>١٤-٩ قابلية الفصل. إذا قررت أي محكمة مختصة أن أي بند من بنود شروط الاستخدام هذه غير صالح أو غير قانوني أو غير قابل للتنفيذ، فسيتم فصل هذا البند عن شروط الاستخدام هذه وستظل البنود المتبقية سارية المفعول بالكامل طالما أن الجوهر الاقتصادي أو القانوني للمعاملات التي تتم بموجب هذا لا يتأثر بأي شكل من الأشكال الضارة ماديًا بأي من الطرفين.
                </p>
                <p>١٤-١٠ لقوة القاهرة. لن يتحمل أي طرف مسؤولية أي خسارة أو ضرر أو أي تأخير أو فشل في الأداء بسبب أفعال خارجة عن سيطرة هذا الطرف سواء كان من الممكن توقع مثل هذه الأفعال بشكل معقول أم لا (بما في ذلك أفعال القضاء والقدر، أو التشريعات أو الإجراءات القضائية أو التنظيمية لأي حكومة إقليمية أو فيدرالية، أو محكمة أو سلطة تنظيمية، أو أفعال أي من المقاولين من الباطن لدينا أو أي طرف ثالث يقدم لنا السلع أو الخدمات، أو الاضطرابات العمالية، أو انقطاع التيار الكهربائي، أو الحظر).
                </p>
                <p>١٤-١١ عدم التنازل. أي تنازل من جانبنا عن أي من أحكام شروط الاستخدام هذه لن يشكل تنازلاً عن أي حكم آخر (سواء كان مشابهًا أم لا)، ولن يشكل أي تنازل من هذا القبيل تنازلاً مستمرًا عن هذا الحكم المعين ما لم ننص على ذلك صراحةً وكتابيًا.
                </p>
                <p>١٤-١٢ الاتصالات. يمكنك الاتصال بنا عبر البريد الإلكتروني على <a
                    href="mailto:care@giftiglobal.com">care@giftiglobal.com</a> ، أو وسائل التواصل الاجتماعي أو الدردشة المباشرة على الموقع، أو عن طريق الاتصال بمركز الاتصال الخاص بنافي +97145621020.</p>
                <p>١٤-١٣ السريان. ستظل جميع الأحكام التي تظل سارية بشكل صريح أو بطبيعتها سارية حتى بعد تعليق أو إنهاء عضويتك في الموقع.
                </p>
            </article>

            <article id="confidentiality-clause">
                <h3>١٥. بند السرية الخاص بجيفتي جلوبال
                </h3>
                <p>١٥-١ يلتزم كل من شركة مايليست وأنت بالمحافظة على سرية أي معلومات سرية أو خاصة يتم الافصاح عنها خلال فترة هذا الاتفاق. وتشمل المعلومات السرية، على سبيل المثال وليس الحصر، الاستراتيجيات التجارية وبيانات العملاء وتفاصيل البرامج. ويتفق الطرف المستلم على استخدام نفس مستوى الرعاية لحماية هذه المعلومات كما يستخدمها لحماية معلوماته السرية الخاصة به، ولكن ليس بأقل من الرعاية المعقولة."
                </p>
                <p>١٥-٢ لا يجوز استخدام المعلومات السرية إلا للأغراض الموضحة في هذه الاتفاقية ولا يجوز الافصاح عنها لأي طرف ثالث دون الحصول على موافقة كتابية مسبقة من الطرف المفصح. وستظل التزامات السرية هذه سارية بعد انتهاء هذه الاتفاقية لمدة عامين.
                </p>
            </article>

            <article id="user-content">
                <h3>١٦. محتوى المستخدم
                </h3>
                <p>١٦-١ يتحمل المستخدمون مسؤولية كل المحتوى الذي ينشرونه أو يرفعونه أو ينقلونه أو يوزعونه أو يخزنونه أو ينشئونه أو ينشرونه من خلال الخدمة، ويشار إليه بشكل جماعي باسم "محتوى المستخدم". كما يتعين أن يتوافق هذا المحتوى مع القواعد الموضحة أدناه، والتي تم وضعها لضمان تجربة إيجابية للجميع. ومع ذلك، لا تضمن هذه القواعد أن الخدمة ستكون خالية من المحتوى الذي ينتهك هذه المعايير.
                </p>
                <p>١٦-٢  يحق لنا إنهاء أي حساب مستخدم وفقًا لتقديرنا. يوافق المستخدمون على عدم المشاركة في أنشطة أو نشر محتوى من شأنه:
                </p>
                <ol>
                    <li>غير قانوني، أو مضايق، أو فاحش، أو صريح جنسيًا، أو عنيف، أو غير مقبول بأي شكل آخر.
                    </li>
                    <li>يتضمن مواد جنسية صريحة.
                    </li>
                    <li>قد يؤدي إلى المسؤولية الجنائية أو المدنية، أو انتهاك القوانين أو اللوائح.
                    </li>
                    <li>يشكل إعلانًا غير مرغوب فيه أو "بريدًا عشوائيًا".
                        </li>
                    <li>ينتهك حقوق الملكية الفكرية.
                    </li>
                    <li>إعادة نشر محتوى من الآخرين دون الحصول على إذن مناسب.
                    </li>
                    <li>يهدف إلى خداع أو تضليل المستخدمين.
                    </li>
                    <li>انتحال شخصية أي شخص أو تمثيل الانتماء بشكل زائف.
                    </li>
                    <li>الكشف عن معلومات خاصة بالآخرين.
                    </li>
                    <li>يحتوي على أكواد أو نصوص ضارة.
                    </li>
                    <li>إذا اعتبرنا ذلك غير مناسب.
                        </li>
                </ol>
                <p>١٦-٣ إذا صادفت أي محتوى غير مقبول، يرجى إخطارنا وسنقوم بمراجعته. نحتفظ بالحق في اتخاذ القرار النهائي بشأن ما إذا كان قد تم انتهاك الشروط وما هي الإجراءات التي يجب اتخاذها.
                </p>
                <p>١٦-٤ على الرغم من أننا لا نراقب أو نحرر محتوى المستخدم بشكل نشط، فقد نقوم بإزالة أو فحص أو تحرير أي محتوى في أي وقت لأي سبب. ويتحمل المستخدمون مسؤولية نسخ المحتوى احتياطيًا واستبدال أي محتوى يقومون بتحميله إلى الخدمة على نفقتهم الخاصة. ولا نتحمل أي مسؤولية عن محتوى المستخدم</p>
                <p>١٦-٥ يحتفظ المستخدمون بملكية المحتوى الخاص بهم ولكنهم يمنحوننا الحق في استخدامه وإعادة إنتاجه وتعديله وتوزيعه عالميًا. يجب على المستخدمين التأكد من التالي:</p>
                <ol>
                    <li>امتلاكهم لحقوق اللازمة لنشر المحتوى.
                    </li>
                    <li>المحتوى دقيق وغير مضلل.
                    </li>
                    <li>لا يشكل استخدامنا للمحتوى انتهاكًا لهذه الشروط أو أي حقوق.
                        </li>
                </ol>
            </article>

            <article id="user-conduct">
                <h3>١٧. سلوك المستخدم
                </h3>
                <p>١٧-١ المستخدمون مسؤولون عن سلوكهم وسلوك أي شخص يستخدم حساباتهم. يتعين على المستخدمين عدم القيام بالتالي:
                </p>
                <ol>
                    <li>التدخل في الخدمة أو تعطيلها.
                    </li>
                    <li>الإبلاغ عن المحتوى أو الإساءة دون مبرر.
                    </li>
                    <li>استخدام الخدمة لأغراض غير قانونية.
                    </li>
                    <li>الإشارة إلى أي ارتباط معنا دون إذن.
                    </li>
                    <li>إنشاء حسابات أو نشر محتوى إذا كان عمرك أقل من 18سنوات من العمر.
                    </li>
                    <li>استخدام حساب مستخدم آخر دون إذن.
                    </li>
                    <li>تعديل الخدمة أو تكييفها أو اختراقها.
                    </li>
                    <li>الوصول إلى الخدمة بطرق غير مصرح بها.
                    </li>
                    <li>تطوير تطبيقات الطرف الثالث التي تتفاعل مع الخدمة دون إذن.
                    </li>
                    <li>تجاوز التدابير الأمنية.
                    </li>
                    <li>انتهاك حقوق الآخرين.
                        </li>
                </ol>
                <p>١٧-٢ إذا تم تعطيل حسابك، فلا يجوز لك إنشاء حساب آخر. لا نتحمل مسؤولية سلوك المستخدم أو أخطائه أو إغفالاته أثناء استخدامك للخدمة حيث يكون استخدام الخدمة يكون على مسؤوليتك الخاصة.
                </p>
            </article>

            <article id="disputes">
                <h3>١٨. النزاعات</h3>
                <p>١٨-١ في حالة وجود مشاكل فنية، يرجى الاتصال بنا على <a
                    href="mailto:care@giftiglobal.com">care@giftiglobal.com</a>وسوف نقوم بمراجعتها ومعالجتها حسب تقديرنا.
                </p>
                <p>١٨-٢ يجوز لنا أن نطلب منك معلومات إضافية لحل شكوى. وتوافق على التعاون الكامل.
                </p>
                <p>١٨-٣ فيما يتعلق للنزاعات المتعلقة بالمشتريات:
                </p>
                <ol>
                    <li>بالنزاع <a href="mailto:care@giftiglobal.com">care@giftiglobal.com</a> يرجى إبلاغنا عبر </li>
                    <li>يجوز لنا تقديم علاج معقول إذا كان ذلك ضروريًا.
                    </li>
                    <li>إذا كانت الشكوى تنتهك شروط الاستخدام هذه أو سياساتنا أو قوانيننا، فقد نقوم برفضها واتخاذ الإجراء المناسب، بما في ذلك الإجراء القانوني.
                    </li>
                </ol>
                <p>١٨-٤ لا نلتزم بحل النزاعات بين المستخدمين وأطراف ثالثة. يجب على المستخدمين حل النزاعات بشكل مباشر.
                </p>
                <p>١٨-٥ نحتفظ بالحق في إلغاء أي طلب في أي وقت لأي سبب.
                </p>
            </article>

            <article id="disclaimers">
                <h3>١٩. إخلاء المسؤولية</h3>
                <p>١٩-١ نعمل كسوق، حيث نوفر إمكانية الوصول إلى بطاقات الهدايا من تجار التجزئة المختلفين. كما أننا لسنا مسؤولين عن إنشاء أو استرداد بطاقات الهدايا من قبل تجار التجزئة ولسنا مسؤولين عن المعاملات التي تتم على مواقع الطرف الثالث. إن استخدام الشعارات والعلامات التجارية الخاصة بجهات خارجية لا يعني أي انتماء إلى هذه الأطراف.
                </p>
                <p>١٩-٢ يكون استخدام الخدمة على مسؤوليتك الخاصة. ونحن نتنصل من جميع الضمانات، سواء كانت صريحة أو ضمنية، بما في ذلك:
                </p>
                <ol>
                    <li>ضمانات قابلية التسويق.
                    </li>
                    <li>ضمانات الملاءمة لغرض معين.
                    </li>
                    <li>ضمانات عدم الانتهاك.
                        </li>
                </ol>
                <p>نحن لا نضمن أن الخدمة سوف تلبي متطلباتك أو تكون خالية من الأخطاء.
                </p>
            </article>

            <article id="disclaimer-of-warranties-and-limitation-of-liability">
                <h3>٢٠. إخلاء المسؤولية عن الضمانات والحد من المسؤولية
                </h3>
                <p>نسعى جاهدين لضمان دقة جميع المعلومات المقدمة من خلال الخدمة، ولكننا لا نضمن أو نقدم أي تعهدات فيما يتعلق بجودة أو دقة أو اكتمال أي بيانات أو معلومات أو منتج أو خدمة. لن نكون مسؤولين تحت أي ظرف من الظروف عن أي أضرار ناجمة عن استخدام الخدمة أو الوظائف ذات الصلة. نحن لسنا مسؤولين عن التأخيرات أو عدم التوفر أثناء الصيانة أو أي تعليق غير مخطط له للوصول لأسباب فنية. يفهم المستخدمون أن أي مادة يتم تنزيلها أو الحصول عليها من خلال الخدمة تتم على مسؤوليتهم الخاصة، وهم مسؤولون عن أي ضرر يلحق بأنظمتهم أو فقدان البيانات.
                </p>
            </article>

            <article id="return-policy">
                <h3>٢١. سياسة الإرتجاع
                </h3>
                <p>٢١-١ طبيعة بطاقات الهدايا الإلكترونية غير القابلة للإرجاع: بطاقات الهدايا الإلكترونية التي تم شراؤها من خلال الموقع الإلكتروني غير قابلة للإرجاع ولا يمكن استرداد قيمتها. بمجرد إصدار بطاقة الهدايا الإلكترونية وتسليمها إلى المتلقي، لا يمكن إرجاعها أو استبدالها نقدًا أو بأي شكل آخر من أشكال الدفع.
                </p>
                <p>٢١-٢ أخطاء الشراء: في حالة حدوث خطأ في شراء بطاقة الهدايا الإلكترونية، مثل عملية شراء مكررة أو مبلغ غير صحيح، يجب عليك إخطار فريق خدمة العملاء لدينا في غضون 24 ساعة من المعاملة. يجوز لنا، وفقًا لتقديرنا، تقديم حل مثل إعادة إصدار بطاقة الهدايا الإلكترونية أو تعديل المبلغ، بشرط عدم استخدام بطاقة الهدايا الإلكترونية.</p>
                <p>٢١-٣ انتهاء الصلاحية والقيود على الاستخدام: قد تكون لبطاقات الهدايا الإلكترونية تواريخ انتهاء صلاحية أو قيود محددة على الاستخدام (شروط وأحكام بطاقات الهدايا) كما هو موضح في وقت الشراء أو على ظهر بطاقة الهدايا. وتقع على عاتق المشتري مسؤولية مراجعة هذه الشروط قبل إتمام المعاملة.
                </p>
                <p>٢١-٤ المعاملات الاحتيالية: يحق لنا التحقيق في أي عمليات شراء يشتبه في كونها احتيالية. وفي الحالات التي يتم فيها تأكيد النشاط الاحتيالي، يجوز لنا إلغاء بطاقة الهدايا الإلكترونية وإخطار السلطات المختصة.
                </p>
            </article>

            <article id="business-continuity-plan">
                <h3>٢٢. خطة استمرارية الأعمال
                </h3>
                <p>٢٢-١ نظرة عامة: نحن ملتزمون بالحفاظ على التشغيل المستمر لخدمات الموقع وحماية مصالح عملائه، وخاصة خلال الأحداث أو الأزمات غير المتوقعة.
                </p>
                <p>٢٢-٢ تقييم المخاطر: نقوم بإجراء تقييمات منتظمة لتحديد المخاطر المحتملة التي قد تؤثر على عملياتنا، بما في ذلك الأعطال التكنولوجية والكوارث الطبيعية وحالات الطوارئ الأخرى.
                </p>
                <p>٢٢-٣ استراتيجية الاستجابة: في حالة وقوع أزمة، نقم بوضع إجراءات لضمان الاستجابة السريعة والمعالجة. وتتضمن استراتيجية الاستجابة لدينا ما يلي:
                </p>
                <ul>
                    <li>التواصل الفوري مع العملاء بشأن انقطاع الخدمة.
                    </li>
                    <li>تفعيل أنظمة النسخ الاحتياطي لضمان استمرارية العمليات.
                    </li>
                    <li>تحديثات منتظمة حول حالة الخدمات من خلال موقعنا الإلكتروني وقنوات التواصل مع العملاء.
                    </li>
                </ul>
                <p>٢٢-٤ البنية التحتية للتكنولوجيا: تم تصميم البنية التحتية للتكنولوجيا لدينا لتكون مرنة وقابلة للتطوير، مما يضمن بقاء منصتنا جاهزة للعمل حتى خلال فترات الازدحام الشديد أو الانقطاعات غير المتوقعة.</p>
                <p>٢٢-٥ دعم العملاء: تم تدريب فريق دعم العملاء لدينا على التعامل مع الاستفسارات والمخاوف أثناء أي أزمة، مما يضمن حصول العملاء على المساعدة والمعلومات في الوقت المناسب فيما يتعلق بعمليات شراء بطاقات الهدايا الإلكترونية الخاصة بهم.
                </p>
                <p>٢٢-٦ المراجعة والتحسين: نعمل باستمرار على مراجعة وتحديث خطة استمرارية الأعمال الخاصة بنا لتعزيز مرونتنا واستجابتنا للمخاطر الجديدة. وتعتبر ملاحظات العملاء ذات قيمة لا تقدر بثمن في هذه العملية، ونحن نشجع الاقتراحات للتحسين.
                </p>
            </article>
        </div>

        <div className="col-md-4 t-col-right">
            <div className="sticky-side p-3">
                <p>مرحبا بكم في جيفتي جلوبال</p>
                <ul className="list-group list-group-flush" style={{listStyleType: "arabic-indic"}}>
                    <li className="border-0 condition-item-style">
                        <a href="#about-our-website">عن موقعنا الإلكتروني</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#eligibility-and-registration-requirements">شروط الأهلية ومتطلبات التسجيل</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#your-obligations">التزاماتك</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#customer-restrictions-and-responsibilities">القيود والمسؤوليات الخاصة بالعملاء</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#intellectual-property-rights">حقوق الملكية الفكرية</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#warranties-representations-undertakings">الضمانات والإقرارات والتعهدات</a>
                    </li>
                    <li className="border-0 condition-item-style">
                    <a href="#liability-indemnities">المسؤولية والتعويضات</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#suspension-termination-cancellation">التعليق والإلغاء وإنهاء الحساب</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#reporting-violations">الإبلاغ عن انتهاكات شروط الاستخدام</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#affiliates-additional-functions">الشركات التابعة والوظائف الإضافية</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#gift-card-information">معلومات بطاقة الهدايا</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#payment-methods-and-protecting-your-security">طرق الدفع وحماية بياناتك</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#fulfillment-of-gift-cards-specific-clause">إتمام طلبات بطاقات الهدايا</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#general">أحكام عامة</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#confidentiality-clause">بند السرية الخاص بجيفتي جلوبال</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#user-content">محتوى المستخدم</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#user-conduct">سلوك المستخدم</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#disputes">النزاعات</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#disclaimers">إخلاء المسؤولية</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#disclaimer-of-warranties-and-limitation-of-liability">إخلاء المسؤولية وحدود
                            المسؤولية</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#return-policy">سياسة الإرجاع</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#business-continuity-plan">خطة استمرارية الأعمال</a>
                    </li>
                </ul>
            </div>
        </div>


    </div>
}

export default ArTerms;
